import { getSettlementConfigPage, setSettlementConfig, setAllSettlementConfig } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      Info: {
        fixed_money: '',
        float_value: ''
      },
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        current: 1,
        total: 0,
        province: '',
        city_name: ''
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.province = '';
      this.pageInfo.city_name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getSettlementConfigPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    addCity() {
      console.log(this.$root.cityList);
      setAllSettlementConfig(this.$root.cityList).then(res => {});
    },
    showEdit(row) {
      this.Info = row;
      this.dialogFormVisible = true;
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    subData() {
      if (!this.Info.fixed_money && !this.Info.float_value) {
        this.$root.ElMessage.error('固定抽成和比例抽成必填一项');
        return false;
      }
      setSettlementConfig(this.Info).then(res => {
        console.log(res);
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.getList();
          this.closeDiv();
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};